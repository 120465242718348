a {
  &.screenshot {
    display: block;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    overflow: hidden;
    &:hover {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
  &.contact-link {
    display: block;
    line-height: 1;
    &:before {
      margin-right: 0.5em;
      vertical-align: middle;
      font-family: "icons" !important;
      font-size: 1.3rem;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
    }
    &.phone {
      &:before {
        content: "\e900";
      }
    }
    &.email {
      &:before {
        content: "\e901";
      }
    }
    &:hover,
    &:active {
      color: white;
    }
  }
}
