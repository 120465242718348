body {
  font-family: $sans-serif;
  @include antialiasing;
  color: rgba(255, 255, 255, 0.8);
}

h1 {
  font-size: 2.5rem;
  font-weight: 300;
  text-align: center;
}

h2 {
  font-size: 1.8rem;
  font-weight: 300;
  margin: 1.5rem 0;
  color: white;
  span {
    text-transform: uppercase;
    color: $green;
  }
}

p {
  line-height: 1.5;
  margin: 0.7em 0;
  &:last-of-type {
    margin-bottom: 0;
  }
}

a {
  text-decoration: none;
}

footer {
  .contact {
    a {
      color: rgba(255, 255, 255, 0.8);
      &:hover,
      &:active {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .language {
    color: rgba(255, 255, 255, 0.8);
    .separator {
      color: rgba(255, 255, 255, 0.5);
    }
    a {
      color: rgba(255, 255, 255, 0.5);
      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
