body {
  background-color: $dark-1;
}

footer {
  background-color: darken($green, 15%);
  .layout-container {
    > * {
      padding: 1rem 0;
      a {
        padding: 0.5rem 0;
      }
    }
  }
}
