body {
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.layout-container {
  max-width: 1200px;
  padding: 0 $site-margin;
  margin: 0 auto;

  .screenshot {
    // margin-bottom: 3rem;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.cards {
  display: flex;
  gap: $site-margin;
}

footer {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  .layout-container {
    display: flex;
    > * {
      display: flex;
    }
    .contact {
      // gap: 2em;
    }
    .language {
      gap: 1em;
      align-items: center;
    }
  }
}

// Media queries

@media screen and (max-width: $bp-h2) {
  h2 {
    span {
      display: block;
    }
  }
}

@media screen and (max-width: $bp-cards) {
  h1 {
    margin: $site-margin 0;
  }
  .cards {
    flex-direction: column;
  }
  .card {
    &:first-of-type {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
    padding-bottom: $site-margin;
    .screenshot {
      margin-bottom: $site-margin;
    }
  }
}

@media screen and (min-width: $bp-cards + 1px) {
  h1 {
    margin: $site-margin * 2 0;
  }
  .card {
    margin-bottom: $site-margin;
  }
  .screenshot {
    margin-bottom: $site-margin;
  }
}

@media screen and (max-width: $bp-footer) {
  footer {
    .layout-container {
      flex-direction: column;
      .contact {
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      }
      .language {
        justify-content: center;
      }
    }
  }
}

@media screen and (min-width: $bp-footer + 1px) {
  footer {
    .layout-container {
      justify-content: space-between;
      > * {
        gap: 1rem;
        a {
          padding: 0.5rem 0;
        }
      }
    }
  }
}
