// Google Fonts

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

// Icons

@font-face {
  font-family: "icons";
  src: url("../fonts/icons/icons.eot?gzhlon");
  src: url("../fonts/icons/icons.eot?gzhlon#iefix") format("embedded-opentype"),
    url("../fonts/icons/icons.ttf?gzhlon") format("truetype"), url("../fonts/icons/icons.woff?gzhlon") format("woff"),
    url("../fonts/icons/icons.svg?gzhlon#icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-envelope-square:before {
  content: "\e901";
}
.icon-phone-square:before {
  content: "\e900";
}

// Font stacks

$sans-serif: "Noto Sans", sans-serif;

// Colors

$dark-1: #161616;
$dark-2: #000000;
$green: #99b72a;

// Margins, padding

$site-margin: 32px;

// Breakpoints

$bp-cards: 500px;
$bp-footer: 600px;
$bp-h2: 860px;
